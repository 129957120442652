import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isJwtValid } from "../../api/auth";

interface UserData {
    email: string;
    role: string;
    name: string;
    picture: string;
}

const ProtectedRoute: React.FC<{ requiredRole?: string }> = ({ requiredRole }) => {
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [userData, setUserData] = useState<UserData | null>(null);

    useEffect(() => {
        const validateJwt = async () => {
            const result = await isJwtValid();
            setIsValid(result.valid);

            if (result.valid) {
                setUserData({
                    email: result.email!,
                    role: result.role!,
                    name: result.name!,
                    picture: result.picture!,
                });
            }
        };
        validateJwt();
    }, []);

    if (isValid === null) {
        return <div>Validation Checking...</div>;
    }

    if (!isValid) {
        return <Navigate to="/login" />;
    }

    if (requiredRole && userData?.role !== requiredRole) {
        return <Navigate to="/unauthorized" />; // 권한이 없는 경우 Unauthorized 페이지로 이동
    }

    return <Outlet />;
};

export default ProtectedRoute;
