import React from 'react';
import { MenuContainer, MenuTitle } from './Menu.styles';
import MenuItem from './MenuItem';

interface MenuProps {
    title: string;
    menuData: { menuId: number; imageUrl: string; name: string; price: string; tag: string; explanation: string; }[];
    onMenuItemSelect: (menuItem: any) => void; // 클릭 시 데이터 전달
}

const Menu: React.FC<MenuProps> = ({ title, menuData, onMenuItemSelect }) => {
    return (
        <div>
            <MenuTitle>{title}</MenuTitle>
            <MenuContainer>
                {menuData.map((item, index) => (
                    <MenuItem
                        key={index}
                        {...item}
                        onItemSelect={onMenuItemSelect} // 메뉴 항목 클릭 시 상위 컴포넌트에 전달
                    />
                ))}
            </MenuContainer>
        </div>
    );
};

export default Menu;
