import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout'; // Layout import

const PageContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
`;

const Section = styled.div`
    margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #555;
`;

const Paragraph = styled.p`
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
`;

const TermsOfServicePage: React.FC = () => {
    return (
        <Layout>
            <PageContainer>
                <Title>Terms of Service</Title>
                <Section>
                    <SectionTitle>1. Acceptance of Terms</SectionTitle>
                    <Paragraph>
                        By accessing and using this website, you agree to be bound by these terms of service. If you do
                        not agree, please do not use our services.
                    </Paragraph>
                </Section>
                <Section>
                    <SectionTitle>2. Use of Services</SectionTitle>
                    <Paragraph>
                        You agree to use our services only for lawful purposes and in a way that does not infringe the
                        rights of others or restrict their use of our services.
                    </Paragraph>
                </Section>
                <Section>
                    <SectionTitle>3. Intellectual Property</SectionTitle>
                    <Paragraph>
                        All content, including text, graphics, logos, and software, is the property of TasteaDeli or its
                        licensors and is protected by copyright and trademark laws.
                    </Paragraph>
                </Section>
                <Section>
                    <SectionTitle>4. Termination</SectionTitle>
                    <Paragraph>
                        We reserve the right to terminate your access to our services at any time without notice for
                        violation of these terms.
                    </Paragraph>
                </Section>
                <Section>
                    <SectionTitle>5. Changes to Terms</SectionTitle>
                    <Paragraph>
                        We may update these terms from time to time. It is your responsibility to review the terms
                        regularly.
                    </Paragraph>
                </Section>
            </PageContainer>
        </Layout>
    );
};

export default TermsOfServicePage;
