import axios from 'axios';
import { setupInterceptors } from './interceptor';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

setupInterceptors(api);

export default api;
