import { AxiosInstance } from 'axios';

export const setupInterceptors = (api: AxiosInstance) => {
    api.interceptors.request.use(
        (config) => {
            // 쿠키에서 JWT 가져오기
            const getCookie = (name: string): string | undefined => {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop()?.split(';').shift();
                return undefined;
            };

            const token = getCookie('jwt'); // 쿠키에서 JWT 읽기
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.status === 401) {
                console.error('Unauthorized: Redirecting to login...');
                window.location.href = '/login'; // 인증 실패 시 로그인 페이지로 리디렉션
            }
            return Promise.reject(error);
        }
    );
};
