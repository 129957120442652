import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { ProtectedRoute } from './components';
import { HomePage, FindUsPage, MenuPage, OrderPage, LoginPage,
    DashboardPage, LoginSuccess, PrivacyPolicyPage, TermsOfServicePage,
    MenuEditPage, UnauthorizedPage, CategoryEditPage } from './pages';
import 'react-data-grid/lib/styles.css';


const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/menu" element={<MenuPage />} />
                <Route path="/find-us" element={<FindUsPage />} />
                <Route path="/order" element={<OrderPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/login-success" element={<LoginSuccess />} />
                <Route path="/dashboard" element={<ProtectedRoute />}>
                    <Route index element={<DashboardPage />} />
                </Route>
                <Route path="/menu-edit" element={<ProtectedRoute requiredRole={"ROLE_ADMIN"} />}>
                    <Route index element={<MenuEditPage />} />
                </Route>
                <Route path="/category-edit" element={<ProtectedRoute requiredRole={"ROLE_ADMIN"} />}>
                    <Route index element={<CategoryEditPage />} />
                </Route>
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />
                <Route path="/terms" element={<TermsOfServicePage />} />
            </Routes>
        </Router>
    );
};

export default App;
