import React, { useEffect, useRef } from 'react';
import { CategoryNavbarContainer, CategoryLink } from './CategoryNavbar.styles';

interface CategoryNavbarProps {
    categories: string[];
    activeCategory: string; // 현재 강조된 카테고리
    onClickCategory: (category: string) => void;
}

const CategoryNavbar: React.FC<CategoryNavbarProps> = ({ categories, activeCategory, onClickCategory }) => {
    const navbarRef = useRef<HTMLDivElement | null>(null); // Navbar 컨테이너 참조
    const categoryRefs = useRef<Record<string, HTMLButtonElement | null>>({}); // 각 카테고리 버튼 참조

    useEffect(() => {
        const activeElement = categoryRefs.current[activeCategory];

        if (activeElement && navbarRef.current) {
            const navbar = navbarRef.current;

            // 요소의 중앙 위치 계산
            const elementLeft = activeElement.offsetLeft;
            const elementWidth = activeElement.offsetWidth;
            const navbarWidth = navbar.offsetWidth;

            // 중앙에 맞추기 위한 스크롤 위치 계산
            const scrollPosition = elementLeft - navbarWidth / 2 + elementWidth / 2;

            // 스크롤 이동
            navbar.scrollTo({
                left: scrollPosition,
                behavior: 'smooth',
            });
        }
    }, [activeCategory]); // activeCategory가 변경될 때마다 실행

    return (
        <CategoryNavbarContainer ref={navbarRef}> {/* Navbar 컨테이너에 ref 추가 */}
            {categories.map((category) => (
                <CategoryLink
                    key={category}
                    ref={(el) => (categoryRefs.current[category] = el)} // 각 카테고리 버튼에 ref 할당
                    onClick={() => onClickCategory(category)}
                    $isActive={activeCategory === category}
                >
                    {category}
                </CategoryLink>
            ))}
        </CategoryNavbarContainer>
    );
};

export default CategoryNavbar;
