import styled from 'styled-components';

export const CategoryNavbarContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    background-color: #282c34;
    padding: 1rem;
    position: sticky;
    top: 90px;
    z-index: 999;
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
`;

interface CategoryLinkProps {
    $isActive: boolean;
}

export const CategoryLink = styled.button<CategoryLinkProps>`
    background: ${({ $isActive }) => ($isActive ? 'var(--hover-bg-color)' : 'transparent')}; /* isActive일 때 배경색 변경 */
    border: none;
    color: var(--primary-text-color);
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem;
    transition: background-color 0.3s; /* 배경색 변경 애니메이션 */
    white-space: nowrap;
    border-radius: 10px;
`;


