import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavbarContainer = styled.nav`
    display: flex;
    flex-direction: column; /* 기본적으로 세로 정렬 */
    align-items: center; /* 기본적으로 가운데 정렬 */
    padding: 1rem 2rem; /* 세로 패딩 증가 */
    background-color: var(--primary-bg-color); /* 전역 배경색 사용 */
    color: #fff;
    position: sticky; /* 화면 상단에 고정 */
    top: 0; /* 상단에 고정될 위치 */
    z-index: 1000; /* 다른 요소 위에 나타나도록 z-index 설정 */

    @media (min-width: 1001px) {
        flex-direction: row; /* 가로 정렬로 변경 */
        justify-content: space-between; /* 로고와 햄버거 버튼 사이의 공간 배분 */
        align-items: center; /* 세로 방향으로 가운데 정렬 */
    }
`;


export const Logo = styled(Link)`
    padding: 0.5rem 2rem; /* 로고 패딩 */
    font-size: 2.2rem; /* 폰트 크기 증가 */
    font-weight: bold; /* 볼드체 적용 */
    color: var(--primary-text-color); /* 전역 텍스트 색상 사용 */
    text-decoration: none; /* 링크 밑줄 제거 */

    &:hover {
        text-decoration: none; /* 호버 상태에서도 밑줄 제거 */
    }
`;

export const NavLinks = styled.div<{ $isOpen: boolean }>`
    display: flex;
    gap: 1.5rem;
    flex-direction: column; /* 세로 정렬 */
    width: 100%;
    max-height: ${({ $isOpen }) => ($isOpen ? '80vh' : '0')};
    overflow: hidden;
    transition: max-height 0.8s cubic-bezier(0.25, 0.8, 0.25, 1), padding 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding: ${({ $isOpen }) => ($isOpen ? '1rem 0' : '0')};
    align-items: center;
    font-size: var(--navlink-font-size); /* 전역 NavLink 폰트 크기 사용 */

    @media (min-width: 1001px) {
        max-height: none;
        display: flex;
        flex-direction: row; /* 가로 정렬 */
        align-items: center;
        font-size: 1rem; /* 큰 화면에서 기본 폰트 크기 */
    }

    a {
        padding: 0.5rem 1rem;
        text-decoration: none;
        color: var(--primary-text-color); /* 전역 텍스트 색상 사용 */
        transition: color 0.3s, font-size 0.3s; /* 폰트 크기 변경 애니메이션 추가 */

        &:hover {
            color: var(--hover-text-color); /* 호버 시 색상 변경 */
            font-size: var(--navlink-hover-font-size); /* 호버 시 폰트 크기 변경 */
        }
    }
`;

export const Hamburger = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute; /* 햄버거 버튼을 절대 위치로 설정 */
    left: 1.3rem; /* 왼쪽 정렬 */
    top: 2.1rem; /* 위치 조정 */
    display: none; /* 기본적으로 숨김 */
    flex-direction: column;
    align-items: center;

    @media (max-width: 1000px) {
        display: flex; /* 모바일에서 보이도록 설정 */
    }

    & .bar {
        background: var(--primary-text-color); /* 전역 텍스트 색상 사용 */
        width: 25px;
        height: 2px;
        margin: 3px 0;
        transition: all 0.2s ease;

        &.top.opened {
            transform: rotate(40deg);
            transform-origin: left;
        }

        &.mid.opened {
            opacity: 0;
        }

        &.bottom.opened {
            transform: rotate(-40deg);
            transform-origin: left;
        }
    }
`;


export const StartOrderButton = styled.button`
    display: none; /* 기본적으로 숨김 */

    @media (min-width: 1001px) {
        display: block; /* 큰 화면에서 보이도록 설정 */
        margin-left: auto; /* 왼쪽 공간을 자동으로 채워서 오른쪽 정렬 */
        padding: 0.5rem 1rem; /* 패딩 추가 */
        background-color: var(--hover-text-color); /* 전역 배경색 사용 */
        color: var(--primary-text-color); /* 전역 텍스트 색상 사용 */
        border: none; /* 테두리 제거 */
        border-radius: 5px; /* 모서리 둥글게 */
        cursor: pointer; /* 포인터 커서 */
        font-size: 1rem; /* 기본 폰트 크기 */
        transition: background-color 0.3s, color 0.3s; /* 애니메이션 효과 */
    }

    @media (max-width: 1000px) {
        display: block; /* 모바일에서 보이도록 설정 */
        margin-top: 1rem; /* 모바일 뷰에서 위쪽 여백 */
        text-align: center;
        padding: 0.5rem 1rem; /* 패딩 추가 */
        background-color: var(--hover-text-color); /* 전역 배경색 사용 */
        color: var(--primary-text-color); /* 전역 텍스트 색상 사용 */
        border: none; /* 테두리 제거 */
        border-radius: 5px; /* 모서리 둥글게 */
        cursor: pointer; /* 포인터 커서 */
        font-size: 1.5rem; /* 모바일에서 폰트 크기 증가 */
    }

    &:hover {
        background-color: var(--primary-text-color); /* 호버 시 배경색 변화 */
    }
`;
