import React, { useEffect, useState } from "react";
import Layout from "../Layout"; // Layout import
import styled from "styled-components";
import { isJwtValid } from "../api/auth";
import { useNavigate } from "react-router-dom";

const DashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
`;

const ProfileImage = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 50%; /* 원형 이미지 */
    margin-bottom: 20px;
`;

const Text = styled.p`
    font-size: 1rem;
    color: #333;
    margin: 0.5rem 0;

    @media (max-width: 600px) {
        font-size: 0.9rem;
    }
`;

const DashboardPage: React.FC = () => {
    const [userData, setUserData] = useState<{ email: string; role: string; name: string; picture: string } | null>(
        null
    );
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            const result = await isJwtValid();
            console.log("JWT Validation Result:", result); // 디버깅용 로그
            if (result.valid) {
                setUserData({
                    email: result.email!,
                    role: result.role!,
                    name: result.name!,
                    picture: result.picture!,
                });
            }
        };
        fetchUserData();
    }, []);

    return (
        <Layout>
            {!userData ? (
                <div>Loading...</div>
            ) : (
                <DashboardContainer>
                    <h1>Dashboard</h1>
                    <ProfileImage src={userData.picture} alt="User Profile"/>
                    <Text>Welcome, {userData.name}</Text>
                    <Text>Email: {userData.email}</Text>
                    <Text>Role: {userData.role}</Text>
                    <button onClick={() => navigate('/menu-edit')}>메뉴 수정 페이지로 이동</button>
                    <button onClick={() => navigate('/category-edit')}>카테고리 수정 페이지로 이동</button>
                </DashboardContainer>
            )}
        </Layout>
    );
};

export default DashboardPage;
