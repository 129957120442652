import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout';
import { useNavigate } from "react-router-dom";

const MaintenanceBanner = styled.div`
    background-color: red; /* 배경색 */
    color: white;
    text-align: center;
    padding: 2rem;
    font-size: 2rem;
    font-weight: bold;
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    white-space: normal; /* 줄 바꿈 허용 */
    word-wrap: break-word; /* 긴 단어도 줄 바꿈 */
    max-width: 90%; /* 화면 크기 제한 */

    @media (min-width: 600px) {
        max-width: 800px; /* 화면이 클 경우 제한 너비 */
        white-space: nowrap; /* 충분한 너비에서는 한 줄로 표시 */
        font-size: 2rem; /* 기본 크기 유지 */
    }

    @media (max-width: 599px) {
        font-size: 1.5rem; /* 작은 화면에서는 글씨 크기 축소 */
        padding: 1.5rem; /* 패딩도 약간 축소 */
    }

    @media (max-width: 400px) {
        font-size: 1.2rem; /* 더 작은 화면에서는 글씨 크기 더 축소 */
        padding: 1rem; /* 패딩 더 축소 */
    }
`;



const BannerText = styled.span`
    display: inline-block;
    animation: blink 1.5s step-start infinite;

    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
`;

const ComboSection = styled.section`
    background-color: #ffe8a1; /* 따뜻한 느낌의 배경색 */
    padding: 3rem 2rem;
    text-align: center;
    margin: 2rem auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* 중앙 정렬과 너비 제한 */
`;

const ComboTitle = styled.h2`
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        font-size: 2rem; /* 모바일 화면에서는 글씨 크기 축소 */
    }
`;

const ComboDescription = styled.p`
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        font-size: 1.2rem; /* 모바일 화면에서는 글씨 크기 축소 */
    }
`;

const ComboHighlight = styled.span`
    color: #d9534f; /* 강조를 위한 색상 */
    font-weight: bold;
`;




const HeroSection = styled.section`
    background-image: url('https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-138018000000000000/banner_1677979917.jpg?size=medium'); /* 화려한 배경 이미지 */
    background-size: cover;
    background-position: center;
    height: 100vh; /* 전체 화면 높이 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 0 2rem;

    @media (max-width: 1000px) {
        height: 70vh; /* 모바일 버전에서는 높이 조정 */
    }
`;

const HeroText = styled.h1`
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1.5rem;

    @media (max-width: 1000px) {
        font-size: 2.5rem;
    }
`;

const SubText = styled.p`
    font-size: 1.5rem;
    margin-bottom: 2rem;

    @media (max-width: 1000px) {
        font-size: 1.2rem;
    }
`;

const OrderButton = styled.button`
    background-color: var(--hover-text-color);
    color: white;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }

    @media (max-width: 1000px) {
        padding: 0.8rem 1.5rem;
        font-size: 1.2rem;
    }
`;

const FeatureSection = styled.section`
    padding: 5rem 2rem;
    text-align: center;
    background-color: #f1f1f1;

    @media (max-width: 1000px) {
        padding: 3rem 1.5rem;
    }
`;

const FeatureTitle = styled.h2`
    font-size: 3rem;
    margin-bottom: 2rem;

    @media (max-width: 1000px) {
        font-size: 2.2rem;
    }
`;

const FeatureGrid = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    flex-wrap: wrap;
`;

const FeatureCard = styled.div`
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;

    @media (max-width: 1000px) {
        max-width: 100%;
    }
`;

const CardTitle = styled.h3`
    font-size: 1.8rem;
    margin-bottom: 1rem;
`;

const CardDescription = styled.p`
    font-size: 1rem;
    color: #777;
`;

const HomePage: React.FC = () => {
    const OnlineOrderURL = 'https://www.clover.com/online-ordering/tastea-deli-austin/'

    const handleOrderButtonClick = () => {
        window.location.href = OnlineOrderURL
    };

    return (
        <>
            {/*<MaintenanceBanner>*/}
            {/*    <BannerText>🚧 This website is currently under maintenance. 🚧</BannerText>*/}
            {/*</MaintenanceBanner>*/}
            <Layout>
                <HeroSection>
                    <HeroText>Your Favorite Sandwiches</HeroText>
                    <SubText>Delicious, fresh, and fast</SubText>
                    <OrderButton onClick={handleOrderButtonClick}>Start Your Order</OrderButton>
                </HeroSection>

                <ComboSection>
                    <ComboTitle>Combo Special Offer!</ComboTitle>
                    <ComboDescription>
                        Purchase a <ComboHighlight>Sandwich</ComboHighlight> or <ComboHighlight>Salad</ComboHighlight>, and enjoy <ComboHighlight>Chips + Drink</ComboHighlight> at a discounted price.
                    </ComboDescription>
                </ComboSection>

                <FeatureSection>
                    <FeatureTitle>Why Choose Us?</FeatureTitle>
                    <FeatureGrid>
                        <FeatureCard>
                            <CardTitle>Fresh Ingredients</CardTitle>
                            <CardDescription>We use only the freshest ingredients to craft our delicious sandwiches.</CardDescription>
                        </FeatureCard>
                        <FeatureCard>
                            <CardTitle>Wide Variety</CardTitle>
                            <CardDescription>Explore our wide variety of subs, sides, and drinks to satisfy your cravings.</CardDescription>
                        </FeatureCard>
                        <FeatureCard>
                            <CardTitle>Safety First</CardTitle>
                            <CardDescription>We prioritize cleanliness and hygiene in every step of preparation, ensuring a safe and healthy meal.</CardDescription>
                        </FeatureCard>
                    </FeatureGrid>
                </FeatureSection>
            </Layout>
        </>
    );
};

export default HomePage;
