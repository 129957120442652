import React from 'react';
import Navbar from '../components/Navbar'; // Navbar import
import Footer from '../components/Footer'; // Footer import
import styled from 'styled-components';

interface LayoutProps {
    children: React.ReactNode;
}

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const MainContent = styled.main`
    flex-grow: 1;
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <LayoutContainer>
            <Navbar />
            <MainContent>{children}</MainContent>
            <Footer />
        </LayoutContainer>
    );
};

export default Layout;
