import React from 'react';
import Layout from "../Layout";

const OrderPage: React.FC = () => {
    return <Layout>
        <h1>Order Page</h1>
    </Layout>
};

export default OrderPage;
