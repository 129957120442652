import api from "./api";
import { jwtDecode } from "jwt-decode";

// Google 인증 URL 생성
export const getGoogleAuthUrl = (clientId: string, redirectUri: string): string => {
    const scope = "openid email profile";
    const responseType = "code";
    return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;
};

// JWT 디코딩
export const decodeJwt = (token: string) => {
    return jwtDecode<{ role: string; exp: number }>(token);
};

// JWT 유효성 검사
export const isJwtValid = async (): Promise<{
    valid: boolean;
    email: string | null;
    role: string | null;
    name: string | null;
    picture: string | null;
}> => {
    try {
        const response = await api.get("/auth/validate-jwt", { withCredentials: true });
        return {
            valid: response.data.valid,
            email: response.data.email || null,
            role: response.data.role || null,
            name: response.data.name || null,
            picture: response.data.picture || null
        };
    } catch (error) {
        console.error("JWT validation failed:", error);
        return { valid: false, email: null, role: null, name: null, picture: null };
    }
};

