import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout'; // Layout import

const PageContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
`;

const Section = styled.div`
    margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #555;
`;

const Paragraph = styled.p`
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
`;

const PrivacyPolicyPage: React.FC = () => {
    return (
        <Layout>
            <PageContainer>
                <Title>Privacy Policy</Title>
                <Section>
                    <SectionTitle>1. Information We Collect</SectionTitle>
                    <Paragraph>
                        We collect personal information such as your name, email address, and payment details when you
                        use our services.
                    </Paragraph>
                </Section>
                <Section>
                    <SectionTitle>2. How We Use Your Information</SectionTitle>
                    <Paragraph>
                        Your information is used to provide, improve, and personalize our services, including
                        communication and promotional offers.
                    </Paragraph>
                </Section>
                <Section>
                    <SectionTitle>3. Sharing Your Information</SectionTitle>
                    <Paragraph>
                        We do not sell your personal information to third parties. However, we may share your
                        information with trusted partners for business purposes.
                    </Paragraph>
                </Section>
                <Section>
                    <SectionTitle>4. Data Security</SectionTitle>
                    <Paragraph>
                        We implement industry-standard security measures to protect your data. However, no online
                        service is 100% secure.
                    </Paragraph>
                </Section>
                <Section>
                    <SectionTitle>5. Your Rights</SectionTitle>
                    <Paragraph>
                        You have the right to access, update, or delete your personal information. Contact us at
                        support@tasteadeli.com for assistance.
                    </Paragraph>
                </Section>
            </PageContainer>
        </Layout>
    );
};

export default PrivacyPolicyPage;
