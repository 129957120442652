import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout'; // Layout import

// 스타일 정의
const FindUsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    @media (max-width: 1000px) {
        padding: 1rem;
    }
`;

const MapWrapper = styled.div`
    width: 100%;
    max-width: 800px;
    height: 400px;
    margin-bottom: 2rem;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    @media (max-width: 1000px) {
        height: 300px;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
`;

const InfoSection = styled.div`
    width: 100%;
    max-width: 800px;
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    @media (max-width: 1000px) {
        padding: 1rem;
    }
`;

const InfoItem = styled.div`
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column; /* 수직 정렬 */
    align-items: flex-start; /* 왼쪽 정렬 */

    span {
        font-weight: bold;
        margin-bottom: 0.5rem; /* 제목과 내용 간의 간격 조정 */
    }
`;


const FindUsPage: React.FC = () => {
    const storeAddress = '922 Congress Ave., Austin, TX 78701';
    const storePhone = '(512) 992-0089';
    const storeHours = ['Sun and Sat: Closed', 'Mon-Fri: 10:30AM - 4:30PM']; // 두 줄로 분리된 시간 정보
    const storeDining = '- Dine-in and Take-out Available';

    return (
        <Layout>
            <FindUsContainer>
                <h1>Find Us</h1>
                <MapWrapper>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.876650042882!2d-97.74300872442733!3d30.27173738168214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b5247b3fe5a9%3A0x6169f100ad509052!2sTastea%20Deli!5e0!3m2!1sen!2sus!4v1697666669803!5m2!1sen!2sus"
                        allowFullScreen
                        loading="lazy"
                        title="Tastea Deli Location"
                    ></iframe>
                </MapWrapper>
                <InfoSection>
                    <InfoItem>
                        <span>Address</span>
                        {storeAddress}
                    </InfoItem>
                    <InfoItem>
                        <span>Contact</span>
                        {storePhone}
                    </InfoItem>
                    <InfoItem>
                        <span>Business Hours</span>
                        <div>{storeHours[0]}</div>
                        <div>{storeHours[1]}</div> {/* 두 줄로 표시 */}
                    </InfoItem>
                    <InfoItem>
                        {storeDining}
                    </InfoItem>
                </InfoSection>
            </FindUsContainer>
        </Layout>
    );
};

export default FindUsPage;
