import React from 'react';
import Layout from '../Layout';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

const UnauthorizedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    padding: 20px;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    color: #dc3545;
    margin-bottom: 20px;
`;

const Message = styled.p`
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 30px;
`;

const Button = styled.button`
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

const UnauthorizedPage: React.FC = () => {
    const navigate = useNavigate();

    const handleGoToLogin = () => {
        navigate('/login'); // 로그인 페이지로 이동
    };

    return (
        <Layout>
            <UnauthorizedContainer>
                <Title>Unauthorized</Title>
                <Message>You do not have permission to view this page.</Message>
                <Button onClick={handleGoToLogin}>Go to Login</Button>
            </UnauthorizedContainer>
        </Layout>
    );
};

export default UnauthorizedPage;
