import React from 'react';
import styled from 'styled-components';
import { FcGoogle } from 'react-icons/fc'; // Google 아이콘 import
import { getGoogleAuthUrl } from '../api/auth';
import Layout from '../Layout';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #f9f9f9;
    padding: 20px;
    box-sizing: border-box;
`;

export const Title = styled.h1`
    font-size: 3rem;
    color: #333;
    margin-bottom: 10px;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 2rem;
    }
`;

export const Subtitle = styled.p`
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.5;
    max-width: 600px;

    @media (max-width: 600px) {
        font-size: 1rem;
        margin-bottom: 20px;
    }
`;

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* 아이콘과 텍스트 사이 간격 */
    padding: 15px 25px;
    font-size: 1.2rem;
    color: white;
    background-color: #4285f4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
        background-color: #357ae8;
        transform: scale(1.05);
    }

    @media (max-width: 600px) {
        font-size: 1rem;
        padding: 10px 20px;
    }

    svg {
        font-size: 1.5rem; /* 아이콘 크기 */
        background-color: white; /* 흰색 배경 */
        border-radius: 50%;
    }
`;

const Divider = styled.hr`
    width: 80%;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 20px 0;
`;

const SmallText = styled.p`
    font-size: 0.9rem;
    color: #999;
    text-align: center;
    margin-top: 20px;
    max-width: 400px;

    @media (max-width: 600px) {
        font-size: 0.8rem;
    }
`;

const LoginPage: React.FC = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
    const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI || 'http://localhost:3000/login-success';

    const handleLogin = () => {
        // Google OAuth URL로 리디렉션
        window.location.href = getGoogleAuthUrl(clientId, redirectUri);
    };

    return (
        <Layout>
            <Container>
                <Title>Welcome to TasteaDeli</Title>
                <Subtitle>
                    Sign in to explore our wide range of delicious sandwiches and salads.
                    Get personalized recommendations and exclusive offers tailored just for you!
                </Subtitle>
                <StyledButton onClick={handleLogin}>
                    <FcGoogle /> {/* Google 아이콘 */}
                    Log in with Google
                </StyledButton>
                <Divider />
                <SmallText>
                    By logging in, you agree to our <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
                    We value your privacy and ensure a secure experience.
                </SmallText>
            </Container>
        </Layout>
    );
};

export default LoginPage;
