// Menu.styles.ts
import styled from 'styled-components';

export const MenuTitle = styled.h2`
    margin: 1rem 1rem 0.4rem 1rem; /* 위 아래 여백 */
    font-size: 2rem; /* 제목 폰트 크기 */
    font-weight: bold; /* 볼드체 적용 */
    color: var(--primary-bg-color); /* 전역 색상 사용 */

`;

export const MenuContainer = styled.div`
    display: flex;
    gap: 1.5rem; /* 아이템 간의 간격 */
    overflow-x: auto; /* 가로 스크롤 허용 */
    padding: 1rem; /* 아래쪽 패딩 추가 */
    margin-bottom: 2rem;
    white-space: nowrap; /* 한 줄로 나열 */
    scroll-behavior: smooth; /* 부드러운 스크롤 효과 추가 */
    -webkit-overflow-scrolling: touch; /* 모바일에서 부드러운 스크롤 */

    /* 스크롤바 커스텀 스타일링 */
    &::-webkit-scrollbar {
        height: 4px; /* 스크롤바의 높이를 6px로 줄임 (가로 스크롤) */
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--primary-bg-color);; /* 스크롤바 색상 */
        background-clip: padding-box; /* 투명한 여백을 유지하며 스크롤바 너비를 조절 */
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1; /* 스크롤바 트랙 배경색 */
        margin: 0 1.1rem; /* 양옆에 마진 추가하여 여백 느낌 */
    }
`;


