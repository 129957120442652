import styled from 'styled-components';

export const FooterContainer = styled.footer`
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
    padding: 2rem 1rem; /* 위아래로 조금 더 패딩을 주어 여유 공간을 줌 */
    text-align: center; /* 전체적으로 중앙 정렬 */
    position: relative;
    bottom: 0;
`;

export const FooterInfo = styled.div`
    display: flex;
    flex-direction: column; /* 세로로 배치 */
    align-items: center; /* 가로 중앙 정렬 */
    text-align: left; /* 각 요소의 텍스트는 왼쪽 정렬 */
    max-width: 300px; /* 적당한 너비로 중앙 배치 */
    margin: 0 auto; /* 가로 중앙에 배치 */
    padding: 1rem 0;
    font-size: 0.8em;
    p {
        margin: 0.3rem 0; /* p 태그 간의 상하 간격 줄이기 */
    }
`;

export const FooterCopyright = styled.p`
    margin-top: 1.5rem; /* 저작권 부분 위에 여백 추가 */
    font-size: 0.7rem; /* 저작권 텍스트 크기를 조금 줄여서 덜 강조 */
`;

export const FooterAddress = styled.p`
    margin-top: 1rem; /* 주소 부분에 여백 추가 */
    font-size: 0.8rem; /* 주소 텍스트 크기 조정 */
`;
