import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isJwtValid } from "../api/auth";

const LoginSuccess: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const validateAndRedirect = async () => {
            try {
                const valid = await isJwtValid(); // 서버에서 JWT 검증
                if (valid) {
                    console.log("JWT is valid. Redirecting to dashboard...");
                    navigate("/dashboard"); // 유효하면 대시보드로 이동
                } else {
                    console.error("JWT is invalid. Redirecting to login...");
                    navigate("/login"); // 유효하지 않으면 로그인 페이지로 이동
                }
            } catch (error) {
                console.error("Error validating JWT:", error);
                navigate("/login"); // 에러 발생 시 로그인 페이지로 이동
            }
        };

        validateAndRedirect();
    }, [navigate]);

    return <div>Processing your login...</div>;
};

export default LoginSuccess;
