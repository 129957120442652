import React, { useState } from 'react';
import { Menu } from '../../components';
import MenuDetails from '../../components/Menu/MenuDetails';

interface MenuItem {
    menuId: number;
    imageUrl: string;
    name: string;
    price: string;
    tag: string;
    category: string;
    explanation: string;
}

interface MenuContainerProps {
    title: string;
    menuData: MenuItem[];
}

const MenuContainer: React.FC<MenuContainerProps> = ({ title, menuData }) => {
    const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(null); // 선택된 메뉴 항목
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const handleMenuItemSelect = (menuItem: MenuItem) => {
        setSelectedMenuItem(menuItem); // 클릭된 메뉴 항목 저장
        setIsOverlayOpen(true); // 오버레이 열기
    };

    const closeOverlay = () => {
        setIsOverlayOpen(false); // 오버레이 닫기
    };

    return (
        <div>
            <Menu
                title={title}
                menuData={menuData}
                onMenuItemSelect={handleMenuItemSelect} // 메뉴 항목 클릭 시 처리
            />
            {isOverlayOpen && selectedMenuItem && (
                <MenuDetails menuItem={selectedMenuItem} onClose={closeOverlay} />
            )}
        </div>
    );
};

export default MenuContainer;
