import api from "./api";
import { CloverCategory } from "../types/CloverCategory";

export const fetchAllCategories = async (): Promise<CloverCategory[]> => {
    const response = await api.get("/category/all");
    return response.data;
};

export const addCategory = async (categoryData: Partial<CloverCategory>): Promise<CloverCategory> => {
    const response = await api.post("/category", categoryData);
    return response.data;
};

export const updateCategory = async (categoryData: Partial<CloverCategory>): Promise<CloverCategory> => {
    if (!categoryData.id) throw new Error("Category ID is required for update");
    const response = await api.put(`/category/${categoryData.id}`, categoryData);
    return response.data;
};

export const deleteCategory = async (categoryId: string): Promise<void> => {
    await api.delete(`/category/${categoryId}`);
};
