import React, { useState } from 'react';
import { NavbarContainer, Logo, NavLinks, Hamburger, StartOrderButton } from './Navbar.styles';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const OnlineOrderURL = 'https://www.clover.com/online-ordering/tastea-deli-austin/'

    const handleOrderButtonClick = () => {
        setIsOpen(!isOpen);
        window.location.href = OnlineOrderURL
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen); // 메뉴 열기/닫기 토글
    };

    const closeMenu = () => {
        setIsOpen(false); // 로고 클릭 시 메뉴 닫기
    };

    return (
        <NavbarContainer>
            <Logo to="/" onClick={closeMenu}> {/* 로고 클릭 시 항상 메뉴 닫기 */}
                TasTeaDeli
            </Logo>
            <Hamburger onClick={toggleMenu}>
                <div className={`bar top ${isOpen ? 'opened' : ''}`} />
                <div className={`bar mid ${isOpen ? 'opened' : ''}`} />
                <div className={`bar bottom ${isOpen ? 'opened' : ''}`} />
            </Hamburger>
            <NavLinks $isOpen={isOpen}>
                <Link to="/menu" onClick={toggleMenu}>Menu</Link> {/* 클릭 시 메뉴 열기/닫기 */}
                <Link to="/find-us" onClick={toggleMenu}>Find Us</Link> {/* 클릭 시 메뉴 열기/닫기 */}
                <StartOrderButton onClick={handleOrderButtonClick}> {/* 클릭 시 메뉴 열기/닫기 */}
                    <Link to="">START ORDER</Link>
                </StartOrderButton>
            </NavLinks>
        </NavbarContainer>
    );
};

export default Navbar;
