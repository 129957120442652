import styled from 'styled-components';

export const StyledButton = styled.button<{ primary?: boolean }>`
  background-color: ${({ primary }) => (primary ? 'blue' : 'gray')};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ primary }) => (primary ? 'darkblue' : 'darkgray')};
  }
`;
