import React, { useState, useEffect, ChangeEvent, useMemo } from "react";
import styled from "styled-components";
import { fetchAllMenus, updateMenuWithImage, addMenu, deleteMenu } from "../api/menu";
import { MenuItem } from "../types/MenuItem";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

// Styled Components
const MenuEditContainer = styled.div`
    padding: 20px;
    text-align: center;
`;

const MenuTableContainer = styled.div`
    width: 100%;
    overflow-x: auto; /* Allow horizontal scroll on mobile */

    @media (max-width: 600px) {
        th, td {
            font-size: 12px;
            padding: 5px;
        }
    }
`;

const MenuTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    min-width: 800px;
    table-layout: fixed; /* Fixed column widths */

    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis; /* Truncate long text */
        white-space: nowrap;
    }

    th:nth-child(3), td:nth-child(3) { /* Price column */
        width: 80px; /* Reduced width */
    }

    th:nth-child(4), td:nth-child(4) { /* Tag column */
        width: 100px; /* Reduced width */
    }

    th:nth-child(7), td:nth-child(7) { /* Actions column */
        width: 150px; /* Expanded width */
    }

    th {
        background-color: #f2f2f2;
        font-weight: bold;
    }

    img {
        max-width: 100px;
        height: auto;
    }

    button {
        padding: 6px 12px;
        background-color: #007bff;
        color: white;
        border: none;
        cursor: pointer;
        margin: 0 5px;
        border-radius: 4px;
        font-size: 14px;
    }

    button:hover {
        background-color: #0056b3;
    }

    @media (max-width: 600px) {
        th, td {
            font-size: 12px;
            padding: 5px;
        }

        th:nth-child(3), td:nth-child(3) { /* Mobile Price column */
            width: 60px; /* Further reduced */
        }

        th:nth-child(4), td:nth-child(4) { /* Mobile Tag column */
            width: 80px; /* Further reduced */
        }

        th:nth-child(7), td:nth-child(7) { /* Mobile Actions column */
            width: 120px; /* Adjusted width */
        }

        button {
            font-size: 12px;
            padding: 4px 8px;
        }
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
`;

const ModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 30px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    text-align: left;

    display: flex;
    flex-direction: column;
    gap: 15px;

    h2 {
        margin: 0 0 10px 0;
    }

    input, select, button { /* Include select */
        padding: 8px;
        font-size: 1rem;
        width: 100%;
        box-sizing: border-box;
    }

    button {
        background-color: #007BFF;
        color: white;
        border: none;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 4px;
    }

    button:hover {
        background-color: #0056b3;
    }

    label {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .image-preview {
        display: flex;
        flex-direction: column;
        gap: 10px;
        img {
            max-width: 200px;       /* Set maximum width */
            max-height: 200px;      /* Set maximum height */
            width: auto;
            height: auto;
            object-fit: contain;    /* Maintain aspect ratio */
            border: 1px solid #ddd; /* Optional: Add a border for better visibility */
            border-radius: 4px;     /* Optional: Add rounded corners */
        }
    }

    @media (max-width: 600px) {
        padding: 20px;

        input, select, button { /* Ensure select is styled on mobile */
            font-size: 0.9rem;
        }

        h2 {
            font-size: 1.2rem;
        }
    }
`;

const CloseButton = styled.button`
    background: #999;
    &:hover {
        background: #777;
    }
`;

const AddMenuButton = styled.button`
    margin-bottom: 20px;
    background-color: #28a745;

    &:hover {
        background-color: #218838;
    }

    @media (max-width: 600px) {
        padding: 6px 12px;
        font-size: 14px;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f0f0f0;
    }
`;

const Title = styled.h1`
    flex: 1;
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
`;

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const SearchInput = styled.input`
    width: 100%;
    max-width: 400px;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.2s;

    &:focus {
        border-color: #007bff;
    }
`;

const AddMenuButtonStyled = styled(AddMenuButton)`
    margin-left: auto;
    padding: 8px 16px;
    font-size: 1rem;
`;

const ImagePreview = styled.img`
    max-width: 200px;
    max-height: 200px;
    width: auto;
    height: auto;
    object-fit: contain;
    border: 1px solid #ddd;
    border-radius: 4px;

    @media (max-width: 600px) {
        max-width: 150px;
        max-height: 150px;
    }
`;

const TAG_OPTIONS = [
    { value: "", label: "Select a tag" },
    { value: "Best", label: "Best" },
    { value: "Vege", label: "Vege" },
    { value: "non-Dairy", label: "non-Dairy" },
];

// Function to extract unique categories
const getUniqueCategories = (menus: MenuItem[]): string[] => {
    const categories = menus.map(menu => menu.category);
    return Array.from(new Set(categories)).filter(Boolean); // Remove duplicates and falsy values
};

// Function to generate category options
const CATEGORY_OPTIONS = (categories: string[]) => [
    { value: "", label: "Select a category" },
    ...categories.map(category => ({ value: category, label: category })),
];

const MenuEditPage: React.FC = () => {
    const navigate = useNavigate();
    const [menus, setMenus] = useState<MenuItem[]>([]);
    const [selectedMenu, setSelectedMenu] = useState<Partial<MenuItem> | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // New Menu Add Modal State
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [newMenu, setNewMenu] = useState<Partial<MenuItem>>({
        name: "",
        price: "",
        tag: "",
        explanation: "",
        category: ""
    });
    const [newFile, setNewFile] = useState<File | null>(null);
    const [newImagePreview, setNewImagePreview] = useState<string | null>(null);

    // Categories State
    const [categories, setCategories] = useState<string[]>([]);

    const loadMenus = async () => {
        try {
            const menuList = await fetchAllMenus();
            setMenus(menuList);
            const uniqueCategories = getUniqueCategories(menuList);
            setCategories(uniqueCategories);
            if (uniqueCategories.length > 0) {
                setNewMenu(prev => ({
                    ...prev,
                    category: uniqueCategories[0],
                }));
            }
        } catch (error) {
            console.error("Failed to fetch menus:", error);
            alert("An error occurred while fetching menus.");
        }
    };

    // Fetch menus and extract categories
    useEffect(() => {
        loadMenus();
    }, []);

    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const handleEditClick = (menu: MenuItem) => {
        setSelectedMenu({...menu});
        setSelectedFile(null);
        setImagePreview(null);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        if (selectedMenu) {
            setSelectedMenu({...selectedMenu, [name]: value});
        }
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target?.result) {
                    setImagePreview(ev.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSave = async () => {
        if (selectedMenu && selectedMenu.menuId) {
            try {
                const updatedMenu = await updateMenuWithImage(
                    {
                        ...selectedMenu,
                        imageUrl: selectedFile ? undefined : selectedMenu.imageUrl, // Keep existing imageUrl if no new file
                    },
                    selectedFile ?? undefined
                );

                setSelectedMenu({ ...selectedMenu, imageUrl: updatedMenu.imageUrl });

                alert("Menu updated successfully!");
                handleCloseModal();
                loadMenus(); // 데이터 재페칭
            } catch (error) {
                console.error("Failed to update menu:", error);
                alert("An error occurred while updating the menu.");
            }
        }
    };

    const handleCloseModal = () => {
        setSelectedMenu(null);
        setSelectedFile(null);
        setImagePreview(null);
    };

    // Handlers for Adding a New Menu
    const handleNewMenuChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setNewMenu({...newMenu, [e.target.name]: e.target.value});
    };

    const handleNewFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setNewFile(file);
            const reader = new FileReader();
            reader.onload = (ev) => {
                if (ev.target?.result) {
                    setNewImagePreview(ev.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAddMenu = async () => {
        if (newMenu.name && newMenu.price && newMenu.category) {
            try {
                const addedMenu = await addMenu(newMenu, newFile ?? undefined);
                handleCloseAddModal();
                alert("Menu added successfully!");
                loadMenus(); // 데이터 재페칭
            } catch (error) {
                console.error("Failed to add menu:", error);
                alert("An error occurred while adding the menu.");
            }
        } else {
            alert("Name, Price, and Category are required.");
        }
    };

    const handleCloseAddModal = () => {
        setShowAddModal(false);
        setNewMenu({
            name: "",
            price: "",
            tag: "",
            explanation: "",
            category: categories.length > 0 ? categories[0] : ""
        });
        setNewFile(null);
        setNewImagePreview(null);
    };

    // Menu Deletion
    const handleDeleteMenu = async (menuId: string) => {
        if (window.confirm("Are you sure you want to delete this menu?")) {
            try {
                await deleteMenu(menuId);
                setMenus((prev) => prev.filter((m) => String(m.menuId) !== menuId));
                alert("Menu deleted successfully!");
            } catch (error) {
                console.error("Failed to delete menu:", error);
                alert("An error occurred while deleting the menu.");
            }
        }
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    // Memoize filtered menus for performance
    const filteredMenus = useMemo(() => {
        return menus.filter((menu) =>
            (menu.name || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
            (menu.category || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
            (menu.tag || "").toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [menus, searchTerm]);


    return (
        <MenuEditContainer>
            <HeaderContainer>
                <BackButton onClick={handleGoBack}>
                    <IoIosArrowBack size={24}/>
                </BackButton>
                <Title>Menu Edit Page</Title>
                <AddMenuButtonStyled onClick={() => setShowAddModal(true)}>
                    Add New Menu
                </AddMenuButtonStyled>
            </HeaderContainer>
            <SearchContainer>
                <SearchInput
                    type="text"
                    placeholder="Search menus..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </SearchContainer>
            <MenuTableContainer>
                {isLoading ? (
                    <p>Loading menus...</p>
                ) : (
                    <MenuTable>
                        <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Tag</th>
                            <th>Explanation</th>
                            <th>Category</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredMenus.map((menu) => (
                            <tr key={menu.menuId}>
                                <td><img src={menu.imageUrl} alt={menu.name}/></td>
                                <td>{menu.name}</td>
                                <td>{menu.price}</td>
                                <td>{menu.tag}</td>
                                <td>{menu.explanation}</td>
                                <td>{menu.category}</td>
                                <td>
                                    <button onClick={() => handleEditClick(menu)}>Edit</button>
                                    <button onClick={() => handleDeleteMenu(String(menu.menuId!))}>Delete</button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </MenuTable>
                )}
            </MenuTableContainer>

            {/* Edit Modal */}
            {selectedMenu && (
                <>
                    <ModalOverlay onClick={handleCloseModal}/>
                    <ModalContent>
                        <h2>Edit Menu</h2>

                        <div>
                            <label htmlFor="tag">Tag</label>
                            <select
                                id="tag"
                                name="tag"
                                value={selectedMenu?.tag || ""}
                                onChange={handleInputChange}
                            >
                                {TAG_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="explanation">Explanation</label>
                            <input
                                id="explanation"
                                type="text"
                                name="explanation"
                                placeholder="Explanation"
                                value={selectedMenu.explanation || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="name">Name</label>
                            <input
                                id="name"
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={selectedMenu.name || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="price">Price</label>
                            <input
                                id="price"
                                type="text"
                                name="price"
                                placeholder="Price"
                                value={selectedMenu.price || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="category">Category *</label>
                            <select
                                id="category"
                                name="category"
                                value={selectedMenu?.category || ""}
                                onChange={handleInputChange}
                            >
                                {CATEGORY_OPTIONS(categories).map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="image">Upload new image</label>
                            <input
                                id="image"
                                type="file"
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </div>
                        {imagePreview ? (
                            <div className="image-preview">
                                <strong>Preview:</strong>
                                <ImagePreview src={imagePreview} alt="preview"/>
                            </div>
                        ) : (
                            selectedMenu?.imageUrl && (
                                <div className="image-preview">
                                    <strong>Current Image:</strong>
                                    <ImagePreview src={selectedMenu.imageUrl} alt={selectedMenu.name}/>
                                </div>
                            )
                        )}

                        <button onClick={handleSave}>Save</button>
                        <CloseButton onClick={handleCloseModal}>Close</CloseButton>
                    </ModalContent>
                </>
            )}

            {/* Add Modal */}
            {showAddModal && (
                <>
                    <ModalOverlay onClick={handleCloseAddModal}/>
                    <ModalContent>
                        <h2>Add New Menu</h2>
                        <div>
                            <label htmlFor="new-name">Name *</label>
                            <input
                                id="new-name"
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={newMenu.name || ""}
                                onChange={handleNewMenuChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="new-price">Price *</label>
                            <input
                                id="new-price"
                                type="text"
                                name="price"
                                placeholder="Price"
                                value={newMenu.price || ""}
                                onChange={handleNewMenuChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="new-category">Category *</label>
                            <select
                                id="new-category"
                                name="category"
                                value={newMenu.category || ""}
                                onChange={handleNewMenuChange}
                                required
                            >
                                {CATEGORY_OPTIONS(categories).map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="new-tag">Tag</label>
                            <select
                                id="new-tag"
                                name="tag"
                                value={newMenu.tag || ""}
                                onChange={handleNewMenuChange}
                            >
                                {TAG_OPTIONS.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="new-explanation">Explanation</label>
                            <input
                                id="new-explanation"
                                type="text"
                                name="explanation"
                                placeholder="Explanation"
                                value={newMenu.explanation || ""}
                                onChange={handleNewMenuChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="new-image">Upload image</label>
                            <input
                                id="new-image"
                                type="file"
                                onChange={handleNewFileChange}
                                accept="image/*"
                            />
                        </div>
                        {newImagePreview && (
                            <div className="image-preview">
                                <strong>Preview:</strong>
                                <ImagePreview src={newImagePreview} alt="preview"/>
                            </div>
                        )}

                        <button onClick={handleAddMenu}>Add Menu</button>
                        <CloseButton onClick={handleCloseAddModal}>Close</CloseButton>
                    </ModalContent>
                </>
            )}
        </MenuEditContainer>
    );
}

export default MenuEditPage;
