import api from "./api";
import { MenuItem } from "../types/MenuItem";

// 모든 메뉴 가져오기
export const fetchAllMenus = async (): Promise<MenuItem[]> => {
    const response = await api.get("/menu/all");
    return response.data;
};

export async function updateMenuWithImage(menu: Partial<MenuItem>, file?: File): Promise<MenuItem> {
    const formData = new FormData();
    formData.append('tag', menu.tag || '');
    formData.append('explanation', menu.explanation || '');
    formData.append('name', menu.name || '');
    formData.append('price', menu.price || '');
    formData.append('category', menu.category || '');
    if (file) {
        formData.append('image', file);
    }

    const res = await api.put(`/menu/${menu.menuId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return res.data;
}

// 메뉴 추가
export const addMenu = async (menuData: Partial<MenuItem>, file?: File): Promise<MenuItem> => {
    const formData = new FormData();
    formData.append('name', menuData.name || '');
    formData.append('price', menuData.price || '');
    formData.append('tag', menuData.tag || '');
    formData.append('explanation', menuData.explanation || '');
    formData.append('category', menuData.category || '');
    if (file) {
        formData.append('image', file);
    }

    const response = await api.post("/menu", formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

// 메뉴 삭제
export const deleteMenu = async (menuId: string) => {
    const response = await api.delete(`/menu/${menuId}`);
    return response.data;
};

// 메뉴 수정 (Tag, Explanation, Image URL)
export const updateMenu = async (menuData: Partial<MenuItem>) => {
    const response = await api.put(`/menu/${menuData.menuId}`, {
        tag: menuData.tag,
        explanation: menuData.explanation,
        imageUrl: menuData.imageUrl,
    });
    return response.data;
};