import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { fetchAllCategories, addCategory, updateCategory, deleteCategory } from "../api/category";
import { CloverCategory } from "../types/CloverCategory";
import {IoIosArrowBack} from "react-icons/io";
import {useNavigate} from "react-router-dom";

const CategoryEditContainer = styled.div`
    padding: 20px;
    text-align: center;
`;

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;

    @media (max-width: 600px) {
        th, td {
            font-size: 12px;
            padding: 5px;
        }
    }
`;

const CategoryTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    min-width: 600px;

    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        vertical-align: middle;
        text-align: center;
    }

    th {
        background-color: #f2f2f2;
        font-weight: bold;
    }

    button {
        padding: 6px 12px;
        background-color: #007bff;
        color: white;
        border: none;
        cursor: pointer;
        margin: 0 5px;
        border-radius: 4px;
        font-size: 14px;
    }

    button:hover {
        background-color: #0056b3;
    }

    @media (max-width: 600px) {
        th, td {
            font-size: 12px;
            padding: 5px;
        }

        button {
            font-size: 12px;
            padding: 4px 8px;
        }
    }
`;

const AddCategoryButton = styled.button`
    margin-bottom: 20px;
    background-color: #28a745;

    &:hover {
        background-color: #218838;
    }

    @media (max-width: 600px) {
        padding: 6px 12px;
        font-size: 14px;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
`;

const ModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 30px;
    border-radius: 8px;
    width: 350px;
    max-width: 90%;
    text-align: left;

    display: flex;
    flex-direction: column;
    gap: 15px;

    h2 {
        margin: 0 0 10px 0;
    }

    input, button {
        padding: 8px;
        font-size: 1rem;
        width: 100%;
        box-sizing: border-box;
    }

    button {
        background-color: #007BFF;
        color: white;
        border: none;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 4px;
    }

    button:hover {
        background-color: #0056b3;
    }

    label {
        font-weight: bold;
        margin-bottom: 5px;
    }

    @media (max-width: 600px) {
        padding: 20px;
        
        input, button {
            font-size: 0.9rem;
        }

        h2 {
            font-size: 1.2rem;
        }
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const BackButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f0f0f0;
    }
`;

const Title = styled.h1`
    flex: 1;
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
`;

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const SearchInput = styled.input`
    width: 100%;
    max-width: 400px;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.2s;

    &:focus {
        border-color: #007bff;
    }
`;

const AddCategoryButtonStyled = styled(AddCategoryButton)`
    margin-left: auto;
    padding: 8px 16px;
    font-size: 1rem;
`;

const CloseButton = styled.button`
    background: #999;
    &:hover {
        background: #777;
    }
`;

const CategoryEditPage: React.FC = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState<CloverCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<Partial<CloverCategory> | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [newCategory, setNewCategory] = useState<Partial<CloverCategory>>({
        name: "",
        sortOrder: 0
    });

    useEffect(() => {
        const loadCategories = async () => {
            const catList = await fetchAllCategories();
            setCategories(catList);
        };
        loadCategories();
    }, []);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredCategories = categories.filter((cat) =>
        cat.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleGoBack = () => {
        navigate(-1); // 이전 페이지로 돌아가기
    };

    const handleEditClick = (cat: CloverCategory) => {
        setSelectedCategory({...cat});
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (selectedCategory) {
            setSelectedCategory({ ...selectedCategory, [e.target.name]: e.target.name === 'sortOrder' ? parseInt(e.target.value) : e.target.value });
        }
    };

    const handleSave = async () => {
        if (selectedCategory && selectedCategory.id) {
            const updated = await updateCategory(selectedCategory);
            setCategories((prev) => prev.map((c) => c.id === updated.id ? updated : c));
            handleCloseModal();
        }
    };

    const handleCloseModal = () => {
        setSelectedCategory(null);
    };

    // 새 카테고리 추가
    const handleNewCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewCategory({...newCategory, [e.target.name]: e.target.name === 'sortOrder' ? parseInt(e.target.value) : e.target.value});
    };

    const handleAddCategory = async () => {
        if (newCategory.name && newCategory.sortOrder !== undefined) {
            const added = await addCategory(newCategory);
            setCategories((prev) => [...prev, added]);
            handleCloseAddModal();
        } else {
            alert("Name과 sortOrder는 필수입니다.");
        }
    };

    const handleCloseAddModal = () => {
        setShowAddModal(false);
        setNewCategory({
            name: "",
            sortOrder: 0
        });
    };

    const handleDeleteCategory = async (id: string) => {
        if (window.confirm("정말 이 카테고리를 삭제하시겠습니까? 이카테고리의 모든 메뉴들도 함꼐 지워집니다.")) {
            await deleteCategory(id);
            setCategories((prev) => prev.filter((c) => c.id !== id));
        }
    };

    return (
        <CategoryEditContainer>
            {/* 헤더 */}
            <HeaderContainer>
                <BackButton onClick={handleGoBack}>
                    <IoIosArrowBack size={24} />
                </BackButton>
                <Title>Category Edit Page</Title>
                <AddCategoryButtonStyled onClick={() => setShowAddModal(true)}>
                    Add New Category
                </AddCategoryButtonStyled>
            </HeaderContainer>

            {/* 검색창 */}
            <SearchContainer>
                <SearchInput
                    type="text"
                    placeholder="Search categories..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </SearchContainer>

            {/* 테이블 */}
            <TableContainer>
                <CategoryTable>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Sort Order</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredCategories.map((cat) => (
                        <tr key={cat.id}>
                            <td>{cat.id}</td>
                            <td>{cat.name}</td>
                            <td>{cat.sortOrder}</td>
                            <td>
                                <button onClick={() => handleEditClick(cat)}>Edit</button>
                                <button onClick={() => handleDeleteCategory(cat.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </CategoryTable>
            </TableContainer>

            {/* 모달들 */}
            {selectedCategory && (
                <>
                    <ModalOverlay onClick={handleCloseModal} />
                    <ModalContent>
                        <h2>Edit Category</h2>
                        <div>
                            <label>Name</label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={selectedCategory.name || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label>Sort Order</label>
                            <input
                                type="number"
                                name="sortOrder"
                                placeholder="Sort Order"
                                value={selectedCategory.sortOrder || 0}
                                onChange={handleInputChange}
                            />
                        </div>
                        <button onClick={handleSave}>Save</button>
                        <CloseButton onClick={handleCloseModal}>Close</CloseButton>
                    </ModalContent>
                </>
            )}

            {showAddModal && (
                <>
                    <ModalOverlay onClick={handleCloseAddModal} />
                    <ModalContent>
                        <h2>Add New Category</h2>
                        <div>
                            <label>Name *</label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={newCategory.name || ""}
                                onChange={handleNewCategoryChange}
                            />
                        </div>
                        <div>
                            <label>Sort Order *</label>
                            <input
                                type="number"
                                name="sortOrder"
                                placeholder="Sort Order"
                                value={newCategory.sortOrder || 0}
                                onChange={handleNewCategoryChange}
                            />
                        </div>
                        <button onClick={handleAddCategory}>Add Category</button>
                        <CloseButton onClick={handleCloseAddModal}>Close</CloseButton>
                    </ModalContent>
                </>
            )}
        </CategoryEditContainer>
    );
};

export default CategoryEditPage;
