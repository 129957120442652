import styled from 'styled-components';

export const StyledInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  
  &:focus {
    border-color: blue;
    outline: none;
  }
`;
