import React from 'react';
import { FooterContainer, FooterInfo, FooterCopyright, FooterAddress } from './Footer.styles';
import { StartOrderButton } from "../Navbar/Navbar.styles";
import {useNavigate} from "react-router-dom";

const Footer: React.FC = () => {
    const navigate = useNavigate();

    const handleOrderButtonClick = () => {
        navigate('/login');
    };

    return (
        <FooterContainer>
            <FooterInfo>
                <p>Contact: nidolight@icloud.com</p>
                <p>Phone: (512) 992-0089</p>
            <FooterAddress>Address: 922 Congress Ave., Austin, TX 78701</FooterAddress>
            </FooterInfo>
            <FooterCopyright>&copy; 2024 tasTeaDeli. All rights reserved.</FooterCopyright>
            <StartOrderButton onClick={handleOrderButtonClick}> Login </StartOrderButton>
        </FooterContainer>
    );
};

export default Footer;
